import React, { Component } from 'react'
import CheckoutSummary from '../../components/Orders/ CheckoutSummary/CheckoutSummary';
import {Route} from 'react-router-dom';
import ContactData from '../Checkout/ContactData/ContactData'
export default class Checkout extends Component {
    state={
        ingredients:null,
        totalprice:0
    }

    componentWillMount(){
        const query = new URLSearchParams(this.props.location.search);
        const ingredients={};
        let price=0;
        for (let param of query.entries()){
            if(param[0]==='price'){
                price=param[1];
            }
            else{
                ingredients[param[0]]=+param[1]
            }
        }
        this.setState({ingredients:ingredients,totalprice:price})
    }

    checkoutCancelledHandler=()=>{
        return(
            this.props.history.goBack()
        )
    }
    checkoutContinuedHandler=()=>{
        return(
            this.props.history.replace("/checkout/contact-data")
        )
    }
    render() {
    return (
        <div>
           <CheckoutSummary 
                    ingredients={this.state.ingredients}
                    checkoutCancelled={this.checkoutCancelledHandler}
                    checkoutContinued={this.checkoutContinuedHandler} /> 
            <Route path={this.props.match.path + '/contact-data' }  render={(props)=>(<ContactData ingredients={this.state.ingredients} price={this.state.totalprice} {...props} />)} />
        </div>
    )   
  }
}
